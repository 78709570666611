<template>
  <div v-if="false">
    <div v-if="+advisor.accuracy > 0" class="flex relative" :class="{'flex-col xxs:flex-row': feedbacks}">
      <!-- accuracy -->
      <div :class="{ 'text-center !pr-40': centered }" class="pr-24">
        <p class="text-h-s font-bold" :class="feedbacks ? 'xxs:!pb-4' : '!pb-4'">
          {{ accuracy }}%
        </p>
        <p class="text-b-s font-medium text-neutral-50">
          Accuracy
        </p>
      </div>

      <div v-if="divider" class="divider absolute bg-neutral-40 w-[0.5px] h-[72%] top-[14%] left-[50%]" />

      <!-- rating -->
      <div>
        <div :class="{ 'justify-center': centered, 'pt-2 xxs:!pt-0': feedbacks}" class="flex items-center !pb-4">
          <se-svg name="star-filled" :height="15" :width="15" />
          <p class="text-h-s font-bold !pl-4">
            {{ rating }}
          </p>

          <p v-if="feedbacks" class="text-h-s underline cursor-pointer text-neutral-50 !pl-8"
             @click="emits('reviewsClicked')">
            {{new Intl.NumberFormat().format(feedbacks)}} reviews
          </p>
        </div>

        <p class="text-b-s font-medium text-neutral-50">
          {{ new Intl.NumberFormat().format(advisor.total_sessions) }} sessions
        </p>
      </div>
    </div>
    <div v-else>
      <div class="pr-24">
        <p class="text-h-s font-bold !pb-4">
          New Advisor
        </p>
        <p class="text-b-s font-medium text-neutral-50">
          No reviews yet
        </p>
      </div>
    </div>
  </div>
  <div class="flex items-center gap-1 text-h-s font-semibold">
    <template v-if="rating">
      {{ rating }}
    </template>
    <se-svg name="star-filled" :height="15" :width="15" class="text-warning-100"/>
    <span class="cursor-pointer leading-[1]"
          :class="{'border-b': props.reviewsCount === null}"
          @click="emits('reviewsClicked')">({{ new Intl.NumberFormat().format(feedbacks ? feedbacks : 0) }})
    </span>
    •
    <template v-if="props.advisor.total_sessions">
      {{ new Intl.NumberFormat().format(props.advisor.total_sessions) }} sessions since
      {{ new Date(props.advisor.created_at).getFullYear() }}
    </template>
    <template v-else>
      New Advisor
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { usersStore } from "@/src/store/users";
import { AdvisorService } from '@/src/modules/advisor/services/advisor.service';
import SeSvg from '@/src/shared/ui-kit/svg/Svg';

const props = withDefaults(defineProps<{
  advisor: any,
  centered?: boolean,
  divider?: boolean,
  clickable?: boolean,
  reviewsCount?: number | null
}>(), {
  centered: false,
  divider: false,
  clickable: false,
  reviewsCount: null,
});


const emits = defineEmits(['reviewsClicked'])
const users = usersStore();
const { userFeedBacksTotal } = storeToRefs(users);

const accuracy = computed(() => AdvisorService.getAccuracy(props.advisor.accuracy));

const rating = computed(() => AdvisorService.getRating(props.advisor.accuracy));
const feedbacks = computed(() => props.reviewsCount ? props.reviewsCount : userFeedBacksTotal.value);
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorAccuracy',
};
</script>
